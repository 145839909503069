import React from 'react'

import Footer from '../../../components/Footer'
import Header from '../../../components/Header'
import { Template } from '../../../components/template'
import { BackButton, ButtonSecondary, Container } from '../characteristics/AllCharacteristics/styles'

const NotFound = ({ pageContext, location }) => {
  const metatag_title = pageContext?.website?.website_info.metatag_title || 'GUIA 360 FORD'
  const metatag_description = pageContext?.website?.website_info.metatag_description
  const metatag_language = pageContext?.website?.website_info.metatag_language
  const privacy_policy_url = pageContext?.website?.website_info.Privacy_policy_url
  const has_cookie_consent = pageContext?.website?.website_info.has_cookie_consent
  const privacy_policy_text = pageContext?.website?.website_info.privacy_policy_text
  const privacy_policy_subtext = pageContext?.website?.website_info.privacy_policy_subtext
  const privacy_policy_accept_button_text = pageContext?.website?.website_info.privacy_policy_accept_button_text

  return (
    <Template
      title={metatag_title}
      description={metatag_description}
      language={metatag_language}
      has_cookie_consent={has_cookie_consent}
      site={pageContext.envs.SITE}
      privacy_policy_text={privacy_policy_text}
      privacy_policy_subtext={privacy_policy_subtext}
      privacy_policy_accept_button_text={privacy_policy_accept_button_text}
    >
      <Header noSearch={true} header={pageContext.website?.header} isNotFound />
      <Container style={{ paddingTop: 85 }}>
        <div className="buttonTextWrapper">
          <BackButton>
            <ButtonSecondary>
              {/* <Link to={`/`}>
                <span className="fds-icon fds-color__text--blue">
                  <span className="fds-font--ford-icons__chevron-left-blue-with-hover"></span>
                </span>
                <span className="text">Voltar</span>
              </Link> */}
            </ButtonSecondary>
          </BackButton>
        </div>
        <div
          style={{
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <div
            className="fds-color__text--primary titleWrapper"
            // , marginTop: breakpoints.sm && '1rem'
          >
            <h1 style={{ fontSize: '50px' }}>{pageContext?.website?.not_found?.title}</h1>
          </div>
          <div className="fds-color__text--primary" style={{ zIndex: 2, textAlign: 'center' }}>
            <p className="text--subtitle" style={{ marginBottom: 37, fontSize: '16px' }}>
              {pageContext?.website?.not_found?.not_found_text}
            </p>
          </div>
          {/* <ButtonNext style={{ width: 258 }}>
            <Link to={`/`}>
              <span style={{ marginRight: '10px', fontSize: '18px !important', lineHeight: '24px !important' }}>
                Tentar novamente
              </span>
              <span
                className="fds-icon fds-color__text--dk-white"
                style={{ fontSize: '9px !important', width: '189.15px !important' }}
              >
                <span className="fds-font--ford-icons__chevron-right" style={{ fontSize: '9px !important' }}></span>
              </span>
            </Link>
          </ButtonNext> */}
        </div>
      </Container>
      <Footer
        privacyPolicyUrl={privacy_policy_url}
        footer={pageContext?.website?.footer}
        header={pageContext.website?.header}
      />
    </Template>
  )
}
export default NotFound
