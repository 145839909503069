import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 100px 70px 100px;
  background-color: #f4f4f4;
  padding-top: 1rem;
  /* margin-right: -8px; */
  min-height: 72vh;

  @media (max-width: 1440px) {
    padding: 30px 75px 75px 75px;
  }

  @media (max-width: 745px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1024px) {
    padding: 15px 60px 15px 60px;
  }

  @media (max-width: 667px) {
    /* padding: 10px 25px 30px 25px; */
    padding: 20px 10px 10px 5px;
  }

  .buttonTextWrapper {
    width: 100%;

    label {
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      display: inline-block;
      font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
      color: #4d4d4d;
    }

    .selectItemWrapper {
      width: 380px;

      @media (min-width: 746px) and(max-width: 1024px) {
        width: 380px;
      }

      @media (min-width: 335px) and (max-width: 745px) {
        width: 100%;
      }

      @media only screen and (max-width: 280px) {
        width: 100%;
      }
    }
  }
`

export const TitlePage = styled.p`
  color: #00095b;
  font-size: 36px !important;
  letter-spacing: 0;
  line-height: 43px !important;
  padding-bottom: 30px;
  font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;

  @media only screen and (min-width: 636px) {
    align-self: start;
  }

  @media (max-width: 745px) {
    max-width: 335px;
  }

  @media only screen and (max-width: 1024px) {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 320px) {
    font-size: 32px !important;
  }

  @media only screen and (max-width: 280px) {
    font-size: 24px !important;
    line-height: 28px !important;
  }
`

export const SelectContainer = styled.div`
  label {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: inline-block;
    font-family: FordAntennaRegular, Arial, Helvetica, sans-serif;
    color: #4d4d4d;
  }

  .selectItem {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    /* min-width: 290px;
    max-width: 300px; */

    @media (max-width: 1024px) {
      /* min-width: 285px;
      max-width: 335px; */
    }
    @media only screen and (max-width: 745px) {
      /* margin-top: 30px; */
      grid-template-columns: 1fr;
      min-width: 285px;
      width: 335px;
    }

    @media only screen and (min-width: 746px) and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const CharactericticsWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px 20px;
  width: fit-content;

  @media only screen and (min-width: 1066px) and (max-width: 1370px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 25px;
  }

  @media only screen and (min-width: 746px) and (max-width: 1065px) {
    grid-template-columns: 1fr 1fr;
    gap: 5px 20px;
  }

  @media only screen and (max-width: 745px) {
    grid-template-columns: 1fr;
    margin-top: 10px;
    gap: 5px;
  }
`

export const CardFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
  width: 100%;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const CardCharac = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);

  height: 100%;
  display: flex;
  flex-direction: column;

  min-width: 290px;
  max-width: 300px;

  @media (max-width: 1024px) {
    min-width: 285px;
    max-width: 335px;
  }

  img {
    width: 100%;
    border-radius: 3px;
  }
`

export const Text = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  p {
    color: #00095b;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 12px;
  }
  a {
    color: #00095b;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 24px;
    text-decoration: none;
    font-family: 'Ford Antenna Cond';

    &:hover {
      color: #1700f4;
      text-decoration: underline;
    }
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    color: #4d4d4d;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 6px;
  }
  select {
    color: #4d4d4d;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 0px 10px;

    height: 50px;
    width: 290px;
    border-radius: 3px;
    background-color: #ffffff;
  }
`

export const ButtonSecondary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: #1700f4;
    /* text-decoration: underline; */
    cursor: pointer;
    .fds-font--ford-icons__chevron-left-blue-with-hover:before {
      color: #1700f4 !important;
    }
  }

  a {
    color: #00095b;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    font-family: 'Ford Antenna Cond';
    text-decoration: none;

    &:hover {
      color: #1700f4;
      /* text-decoration: underline; */
      .fds-font--ford-icons__chevron-left-blue-with-hover:before {
        color: #1700f4 !important;
      }
    }

    @media only screen and (min-width: 1024px) {
    }

    .fds-icon {
      margin-right: 0.5rem;
      place-self: center;
      font-size: 0.8rem;
    }

    .fds-font--ford-icons__chevron-left::before {
      color: #00095b;
      &:hover {
        color: #1700f4 !important;
      }
    }

    .fds-font--ford-icons__chevron-left {
      color: #00095b;
      font-weight: 600;
      &:hover {
        color: #1700f4;
        /* text-decoration: underline; */
      }
      &::before {
        &:hover {
          color: #1700f4;
        }
      }
    }
  }
`

export const BackButton = styled.div`
  max-width: 335px;
  padding: 5px 0px;
  margin: 10px auto;
  width: 100%;
  align-self: start;
  display: flex;

  @media only screen and (min-width: 636px) {
    max-width: 30px;
    margin: 10px 0px 25px;
  }

  @media only screen and (max-width: 1024px) {
    margin: 10px 0px 15px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    font-family: FordAntennaCondRegular;
  }
`

export const BackgroundCharImage = styled.div`
  width: 100%;
  height: 150px;
  background-image: ${props => 'url(' + props.src + ')'};
  background-size: cover;
`
